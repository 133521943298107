import React from 'react';
import logo from './logo.svg';
import './App.css';
// import './assets/icons/fontawesome/css/fontawesome.min.css';

function App() {
  return (
    <div className="App">
        <div className='App-container'>
            <div className="maincontainer">
                <header className='page-header'>
                    <div className='page-header-company-data'>
                        <p style={{margin: '0px', padding: 10, display: 'flex', alignItems: 'center'}}>
                            <i className="material-icons-outlined md-18">mail</i>
                            &nbsp;servicios@racolcomputers.com
                        </p>
                        <p style={{margin: '0px', padding: 10, display: 'flex', alignItems: 'center'}}>
                            <i className="material-icons-outlined md-18">phone</i>
                            &nbsp;(809)-971-3157
                        </p>
                        <p style={{margin: '0px', padding: 10, display: 'flex', alignItems: 'center'}}>
                            <i className="material-icons-outlined md-18">location_on</i>
                            &nbsp;Av. 27 de Febrero No.37, Santiago de los Caballeros
                        </p>
                    </div>
                    <div className='page-header-social-media'>
                        <i style={{ marginLeft: 15}} className='fab fa-facebook'></i>
                        <i style={{ marginLeft: 15}} className='fab fa-linkedin'></i>
                    </div>
                </header>
            </div>
        </div>
        <div className='App-CompanyLogo-container'>
            <div className='maincontainer'>
                <img src="./assets/img/logoSistema.png" alt="company logo" />
            </div>
        </div>
        <div>
            <div style={{marginTop: 40}}>
                <h1 className='text-2xl font-bold' style={{textAlign: "center", marginBottom: 0}}>Pagina en <span style={{color: "red"}}>Desarrollo.</span></h1>
                <h3 className='font-bold' style={{textAlign: "center", marginTop: 0 }}>En desarrollo.... </h3>
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                <img style={{height: "auto", width: "80vh;"}} src="./assets/img/logoSistema.png" alt='Banner'/>
            </div>
        </div>
    </div>
  );
}

export default App;
